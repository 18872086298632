<template>
  <section>
    <header>
      <img alt="Box logo" src="public/jade/public/logo-box.svg">
      <h2>Comprovante de Entrega</h2>
    </header>

    <div class="content__pod--loading" v-if="this.loading">
      <minimal-spinner size=128px hex-color="#198bfd" />
    </div>

    <div class="content__pod--notfound" v-if="!this.loaded && !this.loading">
      <h1>Oops!</h1>
      <h3>Infelizmente não encontramos o que está buscando</h3>
      <h4>Tente <a href="#" @click="$router.go(-1)">voltar</a> para onde estava.</h4>
    </div>

    <div v-if="this.loaded" class="content__pod--main">
      <PodHeader v-if="this.loaded" :pod-data="this.podData"></PodHeader>
      <div class="content__pod--pod">
        <Pod v-if="this.loaded" :pod-data=this.podData></Pod>
      </div>
      <div class="content__pod--row">
        <div class="content__pod--map">
          <PodMap
            v-if="locationsExist"
            :pod-location="podLocation"
            :stop-location="stopLocation"
          ></PodMap>
        </div>
        <div class="content__pod--carousel">
          <PodCarousel v-if="this.podData.pictures" :pictures=this.podData.pictures></PodCarousel>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import {getPodByToken, populatePodData} from "../podsService";
  import Pod from "../Pod.vue";
  import PodMap from "../PodMap.vue";
  import PodCarousel from "../PodCarousel.vue";
  import PodHeader from "../PodHeader.vue";
  import MinimalSpinner from "../../common/MinimalSpinner.vue";

  export default {
    name: "PublicPodsPage",
    components: {MinimalSpinner, PodHeader, PodCarousel, PodMap, Pod},
    data: function(){
      return{
        podData: {},
        stopLocation: {
          lat: null,
          lng: null,
        },
        podLocation: {
          lat: null,
          lng: null,
        },
        loading: false,
        loaded: false,
      }
    },

    computed: {
      locationsExist() {
        return this.stopLocation.lat && this.stopLocation.lng &&
          this.podLocation.lat && this.podLocation.lng;
      }
    },

    methods: {
      getPod(token){
        this.loading=true;

        getPodByToken(token)
          .then(res => {
            if (res.data.hashid)
              this.loaded = true;
            else
              this.loaded = false;

            this.loading=false;

            this.podData = populatePodData(res);

            this.stopLocation = {
              lat: parseFloat(res.data.latAddress),
              lng: parseFloat(res.data.lngAddress),
            }

            this.podLocation = {
              lat: parseFloat(res.data.latSignature),
              lng: parseFloat(res.data.lngSignature),
            }
          })
          .catch(() => {
            this.loaded = false;
            this.loading = false;
          })
      },
    },

    mounted(){
      let token = this.$route.params.token;

      this.getPod(token);
    }
  }
</script>

<style scoped>
  header {
    padding: 10px 30px;
    box-shadow:0 .5rem 1rem rgba(0,0,0,.15)!important;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }

  header img:first-child {
    padding: 5px 0;
    height: 35px;
    width: auto;
  }

  header > h2 {
    margin: 0 0 0 24px;
    font-weight: 500;
  }

  section {
    display: block;
    margin: 0;
    overflow: hidden;
  }

  .content__pod--main {
    display: flex;
    margin: 20px auto 50px;
    width: 1000px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .content__pod--loading {
    width: 100px;
    margin: 100px auto;
  }

  .content__pod--notfound {
    margin: 50px;
  }

  .content__pod--row {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .content__pod--map {
    flex: 1 50%;
    height: 470px;
    margin: 0 20px;
  }

  .content__pod--carousel {
    width: 48%;
    height: 500px;
  }

  .content__pod--pod {
    margin: 0 0 20px 20px;
  }

</style>
